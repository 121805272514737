<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <template slot="footer">
        <a-button v-if="config.type === 'handle'" @click="handleCancel">
          取消
        </a-button>
        <a-button v-if="config.type === 'look'" @click="handleCancel">
          关闭
        </a-button>
        <a-button
          v-if="config.type === 'handle'"
          key="submit"
          type="primary"
          @click="handleOk"
        >
          保存
        </a-button>
      </template>
      <div v-if="config.type === 'del'">
        <div class="modal_text">
          <a-icon theme="twoTone" type="question-circle" />
          <span class="modal_logo">是否确认删除此指令?</span>
        </div>
        <div class="modal_text modal_stext">删除后无法恢复</div>
      </div>
      <div v-if="config.type === 'handle'">
        <div class="modal_content">
          <div class="modal_left">咨询与建议内容：</div>
          <div class="modal_right">{{ config.record.content }}</div>
        </div>
        <div class="modal_content">
          <div class="modal_left">手机号码：</div>
          <div class="modal_right">{{ config.record.contact }}</div>
        </div>
        <div class="modal_content">
          <div class="modal_left">
            <span style="color: red">*</span> 处理结果：
          </div>
          <div class="modal_right add_positon">
            <a-textarea
              :maxLength="100"
              v-model="remarks"
              placeholder="请输入"
              @change="remarksOnchange"
              :auto-size="{ minRows: 8, maxRows: 8 }"
            />
            <span style="position: absolute; right: 10px; bottom: 10px"
              >已输入{{ detLength }}/100</span
            >
          </div>
        </div>
      </div>
      <div v-if="config.type === 'look'">
        <div class="modal_content">
          <div class="modal_left">咨询与建议内容：</div>
          <div class="modal_right">{{ config.record.content }}</div>
        </div>
        <div class="modal_content">
          <div class="modal_left">手机号码：</div>
          <div class="modal_right">{{ config.record.contact }}</div>
        </div>
        <div class="modal_content">
          <div class="modal_left">处理结果：</div>
          <div class="modal_right add_positon">
            <div class="modal_right">
              {{ config.record.remarks }}
              </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { leaveMessageApi } from "@/api/leaveMessage.js";

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    return {
      remarks: "",
      detLength: 0,
      confirmLoading: false,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    handleCancel() {
      this.$emit("handleCancel");
    },
    handleOk() {
      if (this.config.type === "handle") {
        if (this.remarks === "") {
          this.$message.warn("处理结果不能为空");
        } else {
          this.editRemarks();
        }
      }
    },
    remarksOnchange() {
      this.$nextTick(() => {
        this.detLength = this.remarks.length;
      });
    },
    editRemarks() {
      this.confirmLoading = true;
      let params = {
        remarks: this.remarks,
        id: this.config.id,
        rowVersion: this.config.record.rowVersion,
      };
      leaveMessageApi.editRemarks(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          this.modelConfig.show = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add_positon {
  position: relative;
  flex-grow: 1  
}
.modal_content {
  display: flex;
}
.modal_left {
  min-width: 120px;
  text-align: right;
}
.modal_right {
  padding-bottom: 10px;
  // flex-grow: 1;
  word-break: break-all;
}
.modal_text {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding-top: 4px;
  .modal_logo {
    margin-left: 10px;
    margin-top: -6px;
  }
}
.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 30px;
  font-weight: 200;
  height: 60px;
}

.header_contain {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  padding: 0 40px;
  padding-top: 20px;
}

/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
</style>