import axios from "@/config/http";

export const leaveMessageApi = {
  // 获取规则管理table数据
  getTableData(params) {
    return axios.get("/cloud-platform-backend-facade/front-api/v1/content/leaving-message", { params });
  },
  //编辑规则
  editRemarks(params) {
    return axios.put("/cloud-platform-backend-facade/front-api/v1/content/leaving-message", params);
  },

};
