<template>
  <div>
    <div class="search_container">
      <div>
        <span>联系方式：</span>
        <a-input
          class="input_width"
          placeholder="请输入"
          @keydown.native.enter="handleSearch"
          v-model="contact"
        />
      </div>
      <div>
        <span>处理状态：</span>
        <a-select v-model="status" class="input_width">
          <a-select-option
            v-for="(item, index) in statusList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

      <div>
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <a-table
        :loading="loading"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="dataTable"
          :scroll="{ y: 379 }"
      >
        <span slot="status" slot-scope="text">
          <span v-if="text === 1">已处理</span>
          <span v-if="text === 2">未处理</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a v-if="record.status === 2" @click="openModel('handle', record)"
            >处理</a
          >
          <a v-if="record.status === 1" @click="openModel('look', record)"
            >查看</a
          >
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { leaveMessageApi } from "@/api/leaveMessage.js";

export default {
  mixins: [pagination],
  components: {
    ModelTag,
  },
  data() {
    return {
      loading:false,
      contact: "",
      status: 2,
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "人员班组",
        width: "60%",
        okText: "保存",
        delArr: [],
      },

      statusList: [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "已处理",
          value: 1,
        },
        {
          name: "未处理",
          value: 2,
        },
      ],
      columns: [
        {
          ellipsis: true,
          title: "咨询与建议内容",
          dataIndex: "content",
          key: "content",
          align: "center",
          width: "500px",
        },
        {
          title: "处理状态",
          dataIndex: "status",
          key: "status",
          align: "center",
          scopedSlots: { customRender: "status" },
          // width: "110px",
        },
        {
          title: "留言时间",
          dataIndex: "createTime",
          key: "createTime",
          align: "center",
          // width: "110px",
        },
        {
          title: "联系方式",
          dataIndex: "contact",
          key: "contact",
          align: "center",
          // width: "160px",
        },
        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    //重置
    reset() {
      this.contact = "";
      this.status = 2;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading=true
      let params = {
        contact: this.contact,
        status: this.status,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      leaveMessageApi.getTableData(params).then((res) => {
        if (res.success) {
             this.loading=false
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
           this.loading=false
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.getData();
    },

    //调用弹框事件
    openModel(type, record) {
      this.modelConfig.id = record ? record.id : null;
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "handle") {
        this.modelConfig.record = record;
        this.modelConfig.title = "处理";
        this.modelConfig.okText = "保存";
        this.modelConfig.width = "600px";
      } else {
        this.modelConfig.record = record;
        this.modelConfig.title = "查看";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确认";
      }
    },

    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk() {
      this.$message.success("保存成功！");
      this.modelConfig.show = false;
      this.reset();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.input_width {
  width: 240px;
}
.search_container {
  display: flex;
  justify-content: space-between;
}
.add_button {
  margin-top: 20px;
  margin-bottom: 20px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}

/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
  /deep/.ant-table {
    min-height: 379px;
    }
</style>